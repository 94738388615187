<template>
  <div :style="slidePosition" class="z-8 w-100 h-100 ">
    <div class="position-absolute d-flex flex-column align-items-center justify-content-center z-3  spiralWrapper16"
      style=" width: 300vh; height: 300vh; top:50%; left:60%;transform: translate(-50%, -50%); opacity:0;">
      <img src="images/Slide16/Slide16-lines.png" class="spiral16c w-100 h-auto" width="322" height="200"
        aria-label="Yellow Spiral" />
    </div>
    <div class="position-absolute z-2 rounded-circle rotate16b"
      style=" width: 80vw; height: 80vw; top:50%; left:55%;transform: translate(-50%, -50%);">
      <img src="images/Slide6/dottedLine2.svg" class="w-100 h-100" width="1000" height="1000"
        aria-label="Yellow Spiral" />
    </div>
    <div
      class="position-absolute d-flex flex-column align-items-start justify-content-center rounded-circle circle16 z-4 border-warning"
      style="background: rgb(0,161,237); width:120vh; height:120vh; top:50%; left:60%; padding: 8vw; background: rgb(0,161,237); background: linear-gradient(0deg, rgba(0,161,237,1) 0%, rgba(0,108,169,1) 85%); transform: translate(-50%, -50%) scale(0);border:solid 0.75vh;">
      <h6 class="text-white font-vw-3 fade16" style="opacity:1; ">
        1995
      </h6>
      <h6 class="text-white font-vw-2-5 fade16 my-0" style="opacity:1; ">
        ROOTS OF A NOBEL PRIZE
      </h6>
      <p class="mb-0 mt-3 text-16 text-white font-vw-1-25" style="opacity:1; ">
        The 2012 discovery of the Higgs boson, the final piece of the Standard
        Model, was made with the ATLAS and CMS detectors at CERN’s Large Hadron
        Collider (LHC). Beginning in 1995, TRIUMF led Canada’s contribution to
        constructing the LHC, as well as ATLAS-Canada’s contribution to building
        ATLAS, one of the world’s largest and most complex particle detectors.
        TRIUMF staff designed and built parts of ATLAS that helped detect the
        Higgs particle, and provided intensive data analysis to confirm the
        detection. Now, TRIUMF is helping to design and drive upgrades to both
        the LHC and ATLAS to search for dark matter and supersymmetric
        particles—and even physics yet to be imagined. TRIUMF continues to
        operate one of the 10 global Tier-1 data centres to process the data
        produced by ATLAS.
      </p>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { slidePositionPlain } from "@/utils/slidePositioning"
export default {
  data() {
    return {
      spiralPercent: 0
    }
  },
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },
  methods: {
    circle16(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 6000
      })

      animation
        .add({
          targets: ".circle16",
          scale: [0, 1],
          translate: ("50%", "0%"),
          duration: 100,

          delay: (el, i) => 5000 + 30 * i,
          easing: "easeOutCubic"
        })
        .add({
          targets: ".fade16",

          translateY: ["-50%", "0%"],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutSine",
          delay: (el, i) => 1000 + 30 * i
        })
        .add({
          targets: ".text-16",

          translateY: ["35%", "0%"],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutSine"
        })
      animation.seek(
        percentage <= 0.5
          ? animation.duration * (2 * percentage)
          : animation.duration * 1
      )
    },
    spiralWrapperFade(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 0
      })

      animation
        .add({
          targets: ".spiralWrapper16",
          opacity: [0, 1],
          duration: 600,
          delay: 0,
          easing: "linear"
        })
        .add({
          targets: ".spiralWrapper16",
          opacity: [1, 0],
          duration: 400,
          delay: 0,
          easing: "linear"
        })

      animation.seek(animation.duration * percentage)
    },
    startLoop() {
      anime({
        loop: true,
        targets: ".rotate16a",
        rotate: "-5turn",
        direction: "alternate",
        duration: 80000,
        easing: "linear"
      })
      anime({
        loop: true,
        targets: ".rotate16b",
        rotate: "5turn",
        direction: "alternate",
        duration: 130000,
        easing: "linear"
      })
    }
  },
  mounted() {
    this.startLoop()
  },
  watch: {
    innerPosition() {
      this.spiralWrapperFade(this.innerPosition)
      this.circle16(this.innerPosition)
    }
  }
}
</script>

<style></style>
